import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchSettings } from "../actions/settings";

const SettingsProvider = ({children}) => {
    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth?.token);

    useEffect(() => {
        if (token){
            dispatch(fetchSettings(token))
        }

    }, [token]);

    return children
}

export default SettingsProvider;